import { AsyncState } from "react-use/lib/useAsyncFn"
import React, { PropsWithChildren, useEffect, useRef } from "react"
import AlertContainer from "../../core/store/AlertContainer"
import { AxiosError, generateErrorMsg } from "../../core/utils/generateErrorMsg"
import { LoadingComponent } from "./LoadingComponent"

type LoadingWrapProps<T> = {
  state: AsyncState<T>
  render: (data: T) => JSX.Element
  renderLoading?: () => JSX.Element
  loadingOnce?: boolean
  keepLoading?: (data: T) => boolean
}

export const LoadingWrapper: <T>(
  props: PropsWithChildren<LoadingWrapProps<T>>
) => JSX.Element = ({
  state,
  render,
  renderLoading,
  loadingOnce,
  keepLoading,
}) => {
  const { pushAlert } = AlertContainer.useContainer()
  const loaded = useRef(false)

  const handleError = async (err: AxiosError) => {
    pushAlert({ message: generateErrorMsg(err), severity: "error" })
  }

  useEffect(() => {
    state.value && (loaded.current = true)
    if (state.error) {
      handleError(state.error as unknown as AxiosError)
    }
  }, [state])

  const showLoading = (): JSX.Element => {
    return (
      <div className="w-full">
        {renderLoading ? renderLoading() : <LoadingComponent />}
      </div>
    )
  }

  if (!state.value) {
    return showLoading()
  }

  if (loadingOnce && loaded.current) {
    return render(state.value)
  }

  if (state.loading) {
    return showLoading()
  }

  if (keepLoading && keepLoading(state.value)) {
    return showLoading()
  }

  return render(state.value)
}
