import React, { useState } from "react"
import { useAsync } from "react-use"
import WalletContainer from "../../core/store/WalletContainer"
import { EmptyPermission } from "../common/EmptyPermission"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { MainLayout } from "../common/MainLayout"
import { AccountContainer } from "./AccountContainer"

export type AccountProps = {}

export const Account: React.FunctionComponent<AccountProps> = () => {
  const [search, setSearch] = useState("")
  const { walletAddress } = WalletContainer.useContainer()
  const checkInstalledMetamask = useAsync(async () => {
    return {
      installed:
        typeof window !== "undefined" && window.ethereum && walletAddress,
    }
  }, [walletAddress])

  return (
    <MainLayout search={search} setSearch={value => setSearch(value)}>
      <LoadingWrapper
        state={checkInstalledMetamask}
        keepLoading={data => !data}
        render={check =>
          check.installed ? (
            <AccountContainer
              search={search}
              setSearch={value => setSearch(value)}
            />
          ) : (
            <EmptyPermission />
          )
        }
      />
    </MainLayout>
  )
}
