import { CircularProgress, useTheme } from "@mui/material"
import React from "react"

export type LoadingComponentProps = {}

export const LoadingComponent: React.FunctionComponent<LoadingComponentProps> =
  () => {
    const { palette } = useTheme()
    return (
      <div className="flex h-[80vh] w-full items-center justify-center">
        <CircularProgress
          sx={{
            color: palette.primary.main,
          }}
        />
      </div>
    )
  }
