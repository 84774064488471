import { useMediaQuery, useTheme } from "@mui/material"
import { navigate } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { GetAccountResponse } from "../../core/type/Response"
import { CollectionWrapper } from "../collection/CollectionWrapper"
import { PrimaryButton } from "../common/PrimaryButton"
import { SearchWrapper } from "../common/SearchWrapper"
import { AssetsWrapper } from "./AssetsWrapper"
import { EmptyData } from "../common/EmptyData"

export type AccountDetailProps = {
  listData: GetAccountResponse
  walletAddress: string
  search: string
  setSearch: (value: string) => void
  tab: number
}

export const AccountDetail: React.FunctionComponent<AccountDetailProps> = ({
  listData,
  walletAddress,
  search,
  setSearch,
  tab,
}) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isPad = useMediaQuery(breakpoints.down("xl"))
  const ownNFT = listData.ownNFTs.filter(
    item =>
      item.owner?.toUpperCase() === walletAddress.toUpperCase() &&
      (item.metadata?.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.metadata?.collectionName
          ?.toLowerCase()
          .includes(search.toLowerCase()))
  )

  const ownCollection = listData.ownCollections.filter(item =>
    item.name?.toLowerCase().includes(search.toLowerCase())
  )

  const a11yPanelProps = (index: number) => {
    return {
      role: "tabpanel",
      hidden: tab !== index,
      id: `simple-tab-${index}`,
      "aria-labelledby": `simple-tab-${index}`,
    }
  }

  return (
    <>
      <div {...a11yPanelProps(0)}>
        {listData.ownNFTs.length > 0 ? (
          <>
            {isPad && (
              <div className="mb-2 flex justify-center lg:justify-end">
                <SearchWrapper search={search} setSearch={setSearch} />
              </div>
            )}
            {ownNFT.length > 0 ? (
              <div className={`px-5 pb-28 ${isPad ? "" : "pt-4"}`}>
                <div className="flex flex-wrap align-top">
                  {ownNFT.map((item, index) => (
                    <AssetsWrapper
                      item={item}
                      key={index}
                      onClick={() =>
                        navigate(`/${item.address}/${item.tokenId}`)
                      }
                    />
                  ))}
                </div>
                <div className="mt-5 border-t-2 border-dashed border-gray-300 pt-8 text-center">
                  <PrimaryButton
                    color="primary"
                    variant="contained"
                    onClick={() => navigate("/create_nft")}
                  >
                    {t("str_create_nft")}
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <EmptyData
                height="70vh"
                img={"/images/search_no_found.png"}
                message={t("str_search_empty_message")}
              />
            )}
          </>
        ) : (
          <EmptyData
            img={"/images/empty_nft.png"}
            message={t("str_nft_empty_message")}
          >
            <PrimaryButton
              sx={{
                height: 60,
                width: "100%",
                fontSize: 21,
                marginTop: 5,
              }}
              color="primary"
              variant="contained"
              onClick={() =>
                listData.ownCollections.length > 0
                  ? navigate("/create_nft")
                  : navigate("/create_collection")
              }
            >
              {listData.ownCollections.length > 0
                ? t("str_create_nft")
                : t("str_create_collection")}
            </PrimaryButton>
          </EmptyData>
        )}
      </div>
      <div {...a11yPanelProps(1)}>
        {listData.ownCollections.length > 0 ? (
          <>
            {isPad && (
              <div className="mb-2 flex justify-center lg:justify-end">
                <SearchWrapper search={search} setSearch={setSearch} />
              </div>
            )}
            {ownCollection.length > 0 ? (
              <div className={`px-5 pb-28 ${isPad ? "" : "pt-4"}`}>
                <div className="flex flex-wrap align-top">
                  {ownCollection.map((item, index) => (
                    <CollectionWrapper
                      item={item}
                      key={index}
                      onClick={() =>
                        navigate(`/collection/${item.collectionID}`)
                      }
                    />
                  ))}
                </div>
                <div className="mt-5 border-t-2 border-dashed border-gray-300 pt-8 text-center">
                  <PrimaryButton
                    color="primary"
                    variant="contained"
                    onClick={() => navigate("/create_collection")}
                  >
                    {t("str_create_collection")}
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <EmptyData
                height="70vh"
                img={"/images/search_no_found.png"}
                message={t("str_search_empty_message")}
              />
            )}
          </>
        ) : (
          <EmptyData
            img={"/images/empty_collection.png"}
            message={t("str_collection_empty_message")}
          >
            <PrimaryButton
              sx={{
                height: 60,
                width: "100%",
                fontSize: 21,
                marginTop: 5,
              }}
              color="primary"
              variant="contained"
              onClick={() => navigate("/create_collection")}
            >
              {t("str_create_collection")}
            </PrimaryButton>
          </EmptyData>
        )}
      </div>
    </>
  )
}
