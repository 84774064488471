import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box } from "@mui/material"
import { providers } from "ethers"
import { navigate } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { DialogTypeEnum } from "../../core/const/DialogTypeEnum"
import AlertContainer from "../../core/store/AlertContainer"
import { RPCError } from "../../core/type/RPCResponse"
import { PrimaryButton } from "./PrimaryButton"
import LoginIcon from "@mui/icons-material/Login"

export type EmptyPermissionProps = {}

export const EmptyPermission: React.FunctionComponent<EmptyPermissionProps> =
  () => {
    const { t } = useTranslation()
    const { openModal } = AlertContainer.useContainer()
    const goToLogin = () => {
      typeof window !== "undefined" &&
        window.open("https://metamask.app.link/dapp/mint.nft-hack.jp")
    }

    const checkInstalledMetamask = () => {
      return typeof window !== "undefined" && window.ethereum
    }

    const signIn = async (): Promise<void> => {
      if (checkInstalledMetamask()) {
        try {
          const provider = new providers.Web3Provider(window.ethereum)
          await provider.send("eth_requestAccounts", [])
          localStorage.setItem("metamask_connect", "true")
          location.reload()
        } catch (err) {
          if ((err as RPCError)?.code === -32002) {
            openModal({
              title: t("str_connect_error_title"),
              content: (
                <div className="mb-4">{t("str_connect_error_content")}</div>
              ),
              type: DialogTypeEnum.ERROR,
            })
          }
        }
      }
    }

    return (
      <div className="fixed top-0 left-0 z-[1200] h-screen w-full bg-black/[.85]">
        <div className="flex flex-col items-center px-5 pt-[10vh]">
          <Box
            className="flex w-1/5 min-w-[60%] cursor-pointer items-center justify-center rounded-full border md:min-w-[340px]"
            sx={{
              aspectRatio: "1 / 1",
              marginBottom: 4,
              boxShadow: checkInstalledMetamask()
                ? "0px 0px 12px 4px rgb(38 195 212 / 50%)"
                : "",
              pointerEvents: checkInstalledMetamask() ? "" : "none",
              backgroundColor: checkInstalledMetamask()
                ? "primary.light"
                : "#747171",
              "&:hover": {
                borderStyle: "solid",
                borderColor: "primary.main",
              },
            }}
            onClick={() => signIn()}
          >
            <Box
              className="relative flex w-[90%] flex-col items-center justify-center rounded-full"
              sx={{
                aspectRatio: "1 / 1",
                boxShadow: "0px 0px 10px 3px rgb(221 248 253 / 50%)",
                backgroundColor: checkInstalledMetamask()
                  ? "#FFFFFF"
                  : "#9e9e9e",
              }}
            >
              <img
                className="pointer-events-none mb-1"
                src={"/images/metamask-stacked.svg"}
                alt="metamask"
              />
            </Box>
          </Box>
          <PrimaryButton
            sx={{
              height: 60,
              width: "100%",
              maxWidth: 400,
              fontSize: 18,
              marginBottom: 4,
              borderRadius: 50,
              fontWeight: "normal",
              "&.MuiLoadingButton-root:not(.MuiButton-outlined)": {
                backgroundColor: "text.primary",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              },
            }}
            variant="contained"
            className="inline-flex items-center"
            onClick={() => navigate("/how_to_use")}
          >
            <span className="flex-1 text-center">
              {t("str_how_to_use_to_know_with_doc")}
            </span>
            <ChevronRightIcon fontSize="large" fontWeight={600} />
          </PrimaryButton>
          <PrimaryButton
            sx={{
              height: 60,
              width: "100%",
              maxWidth: 400,
              fontSize: 18,
              marginBottom: 4,
              borderRadius: 50,
              fontWeight: "normal",
              "&.MuiLoadingButton-root:not(.MuiButton-outlined)": {
                backgroundColor: "text.primary",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              },
            }}
            variant="contained"
            className="inline-flex items-center"
            onClick={() => navigate("/how_to_use_video")}
          >
            <span className="flex-1 text-center">
              {t("str_how_to_use_to_know_with_video")}
            </span>
            <ChevronRightIcon fontSize="large" fontWeight={600} />
          </PrimaryButton>

          <PrimaryButton
            sx={{
              height: 60,
              width: "100%",
              maxWidth: 400,
              fontSize: 18,
              borderRadius: 50,
              fontWeight: "normal",
              "&.MuiLoadingButton-root:not(.MuiButton-outlined)": {
                backgroundColor: "text.primary",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              },
            }}
            variant="contained"
            className="inline-flex items-center"
            onClick={() => goToLogin()}
          >
            <div className="flex flex-1 flex-col items-center text-center">
              <span>{t("str_metamask_is")}</span>
              <span>{t("str_login")}</span>
            </div>
            <LoginIcon fontSize="large" fontWeight={600} />
          </PrimaryButton>
        </div>
      </div>
    )
  }
