export type AxiosError = {
  response: {
    data: string | { code: string; msg: string }
    status: number
    statusText: string
  }
}

export function generateErrorMsg(err: AxiosError): string {
  let errorMsg = `${err}`
  if (err.response) {
    if (typeof err.response.data === "object") {
      errorMsg = err.response.data.msg
    } else {
      errorMsg = `${err.response.status}:${err.response.statusText}`
    }
  }
  return errorMsg
}
