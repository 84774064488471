import { Box, useTheme } from "@mui/material"
import React from "react"
import { ReactSVG } from "react-svg"
import { Collection } from "../../core/api"
import { replaceEndOfLineToSpace } from "../../core/utils/convertEndOfLine"

export type CollectionWrapperProps = {
  item: Collection
  onClick: () => void
}

export const CollectionWrapper: React.FunctionComponent<CollectionWrapperProps> =
  ({ item, onClick }) => {
    return (
      <Box
        sx={{
          "&:hover": { borderColor: "primary.main", borderStyle: "solid" },
        }}
        className="c-nft__item cursor-pointer hover:border"
        onClick={onClick}
      >
        <Box
          className="c-nft__item--media rounded-t-[10px]"
          sx={{ "&.c-nft__item--media": { aspectRatio: "3 / 2" } }}
        >
          <img
            className="mb-0"
            src={item.feature_image}
            alt={item.name || ""}
          />
        </Box>
        <div className="px-2 pb-2">
          <Box
            className="mb-2 flex justify-between px-2 py-1"
            sx={{ backgroundColor: "#f5f8fa" }}
          >
            <div className="text-h5 flex-1 font-normal">
              {item.category || ""}
            </div>
            <div className="flex items-center text-[14px]">
              <ReactSVG
                src={`/images/crypto/${item.blockchain}.svg`}
                className="mr-1 w-[8px]"
              />
              <span>{item.blockchain}</span>
            </div>
          </Box>
          <Box className="c-nft__item--ttl" sx={{ color: "primary.main" }}>
            {item.name || ""}
          </Box>
          <div className="c-nft__item--txt">
            {replaceEndOfLineToSpace(item.descript || "")}
          </div>
        </div>
      </Box>
    )
  }
