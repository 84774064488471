import { Box, Tab, Tabs } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { AppContext } from "../../AppContext"
import AlertContainer from "../../core/store/AlertContainer"
import WalletContainer from "../../core/store/WalletContainer"
import { GetAccountResponse } from "../../core/type/Response"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { AccountDetail } from "./AccountDetail"

export type AccountContainerProps = {
  search: string
  setSearch: (value: string) => void
}

export const AccountContainer: React.FunctionComponent<AccountContainerProps> =
  ({ search, setSearch }) => {
    const { t } = useTranslation()
    const { walletAddress } = WalletContainer.useContainer()
    const { pushAlert } = AlertContainer.useContainer()
    const [tab, setTab] = React.useState(0)

    const a11yProps = (index: number) => {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      }
    }

    const nftData = useAsync(async () => {
      return Promise.all([
        AppContext.apiExecutor.nftOwnedPost({
          walletAddress: walletAddress,
        }),
        AppContext.apiExecutor.collectionOwnedPost({
          walletAddress: walletAddress,
        }),
      ])
        .then(([ownNFTs, ownCollections]) => {
          return {
            ownNFTs: ownNFTs?.data.data || [],
            ownCollections: ownCollections?.data.data || [],
          }
        })
        .catch(() => {
          pushAlert({
            message: t("str_get_account_error"),
            severity: "error",
          })
        })
    }, [])

    return (
      <div className="px-5 py-10">
        <Box className="mb-5" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiButtonBase-root": {
                fontFamily: "inherit",
              },
            }}
            value={tab}
            onChange={(event: React.SyntheticEvent, newValue: number) =>
              setTab(newValue)
            }
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            {["NFT", t("str_collection")].map((tab, index) => (
              <Tab
                key={index}
                sx={{ color: "text.secondary" }}
                label={tab.toString()}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <LoadingWrapper
          state={nftData}
          keepLoading={data => !data}
          render={listData => (
            <AccountDetail
              search={search}
              setSearch={setSearch}
              listData={listData as GetAccountResponse}
              walletAddress={walletAddress}
              tab={tab}
            />
          )}
        />
      </div>
    )
  }
