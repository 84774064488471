import * as React from "react"
import { Account } from "../components/account/Account"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getCustomConfig } from "../core/utils/getCustomConfig"

const AccountPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <Seo
        title={getCustomConfig().title_suffix}
        favicon={getCustomConfig().favicon}
      />
      <Account />
    </Layout>
  )
}

export default AccountPage
